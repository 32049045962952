<template>
  <div>
    <h1 class="h2">{{ Title }}</h1>

    <div class="my-5">
      <Btn flat color="primary" icon="backspace" @click="back">戻る</Btn>
      <Btn
        class="ml-3"
        color="info"
        icon="details"
        :disabled="edit"
        @click="edit = true"
        >編集</Btn
      >
    </div>

    <v-form
      v-model="valid"
      ref="form"
      :disabled="!edit"
      @submit.prevent="submit"
    >
      <template>
        <DatePicker
          v-model="targetdate"
          label="日付"
          prepend-icon="mdi-calendar"
          outlined
          type="day"
          :rules="[Rules.Required]"
        />
        <v-autocomplete
          v-model="selectShozoku"
          :items="shozokus"
          label="所属"
          :rules="[Rules.Required]"
          outlined
          item-text="name"
          item-value="code"
          prepend-icon="mdi-form-select"
          return-object
        >
        </v-autocomplete>
        <v-text-field
          v-model="eventname"
          label="イベント内容"
          prepend-icon="mdi-text"
          outlined
          maxlength="50"
          :rules="[Rules.Required]"
        ></v-text-field>
        <v-radio-group
          v-model="selectDelete"
          row
          label="削除フラグ:"
          prepend-icon="mdi-toggle-switch"
        >
          <v-radio label="削除" :value="SelectsDelete.Delete"></v-radio>
          <v-radio label="有効" :value="SelectsDelete.Default"></v-radio>
        </v-radio-group>
      </template>
      <div class="d-flex flex-row-reverse">
        <v-btn type="login" color="success" :disabled="!edit">
          完了
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import DailyEvent from "@/models/DailyEvent";

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const SelectsDelete = {
  Delete: 0,
  Default: 1
};

export default {
  name: "DailyEventDetails",
  mixins: [Common, Forms, Api, ShowDialogs],
  props: {
    title: String,
    prosess: String,
    items: Array,
    args: Object
  },
  data() {
    return {
      params: null,
      add: false,
      edit: false,
      recordId: null,
      targetdate: DATENOW,
      shozokus: [],
      selectShozoku: { code: "", name: "" },
      eventname: "",
      selectDelete: SelectsDelete.Default,
      SelectsDelete
    };
  },
  computed: {
    Title() {
      return (
        this.title + " " + (this.add ? "追加" : this.edit ? "編集" : "詳細")
      );
    }
  },
  methods: {
    complete() {
      console.log("complete", this.kbn);
      if (!this.$refs.form.validate()) return;
    },
    async submit() {
      if (!this.$refs.form.validate()) return;
      console.log("submit", this.add);
      this.$loading();
      try {
        const param = new DailyEvent(
          this.recordId,
          this.targetdate,
          this.selectShozoku.code,
          this.eventname,
          this.selectDelete
        );

        console.log("submit_param", param);

        if (this.add) {
          const data = await this.$post(this.Paths.dailyEvent, param);
          this.recordId = data.recordId;
          this.add = false;
        } else {
          await this.$put(this.Paths.dailyEvent, param);
        }

        this.$info("更新しました。", "イベント情報");
        this.edit = false;
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    back() {
      const path = "/list/daily-event";
      this.$router.push({
        path: path,
        query: { filter: this.params.filter }
      });
    },
    async getShozoku() {
      console.log("getShozoku");
      return await this.$get(this.Paths.shozokuFilter);
    }
  },
  async created() {
    console.log(this.name, "created");
    this.params = this.$route.params;

    this.$loading();
    try {
      if (this.prosess && typeof this.prosess === "string")
        this.prosess = this.prosess.toLowerCase();

      switch (this.prosess) {
        case "add":
          this.add = true;
          this.edit = true;
          break;
        case "edit":
          this.add = false;
          this.edit = true;
          break;
        default:
          break;
      }

      this.shozokus = await this.getShozoku();

      if (!this.add) {
        const dt = new Date(this.args.targetDate);
        this.targetdate =
          dt.getFullYear() +
          "-" +
          ("00" + Number(dt.getMonth() + 1)).slice(-2) +
          "-" +
          ("00" + Number(dt.getDate())).slice(-2);

        this.eventname = this.args.eventName;

        if (this.args.isValid === "0") {
          this.selectDelete = this.SelectsDelete.Delete;
        }

        const filterd = this.shozokus.filter(
          e => e.code === String(this.args.affiliationId)
        );
        if (filterd.length > 0) {
          this.selectShozoku = filterd[0];
        }
        this.recordId = this.args.recordId;
      }
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  }
};
</script>

<style></style>
